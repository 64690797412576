<template>
  <f7-toolbar tabbar labels position="bottom" class="product-toolbar" v-if="$configs.chatEnabled">
    <ChatNavigationComponent class="fill chat" :orderkey="order" />
  </f7-toolbar>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
// import ChatNavigationComponent from "@/components/chat/ChatNavigationComponent.vue";

export default defineComponent({
  name: 'OrderToolbarComponent',
  components: {
    ChatNavigationComponent: defineAsyncComponent(() => import(/* webpackChunkName: "left-panel" */ /* webpackMode: "lazy" */ '@/components/chat/ChatNavigationComponent.vue'))
  },
  props: { order: String }
})
</script>

<style scoped>
.md .product-toolbar .toolbar-inner a.fill.chat {
  width: calc(100% - 20px);
  height: 100%;
  padding: 0px;
  border-radius: 0px;
  background: #fff;
  color: #222;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>
